import { MenuItem, Select, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../../reducers/rootReducer'
import { setCurrentCamera, setCurrentCameraId } from '../../slices/cameraSlice'
import { setCurrentCoilGroup, setCurrentCoilGroupId } from '../../slices/coilGroupSlice'
import { setCoils, setCurrentCoil, setMultipleCurrentCoil } from '../../slices/coilSlice'
import { setImage } from '../../slices/thermalImageSlice'
import { getCameraByCoilgroup, getCoilgroupByCamera, getCoilgroups } from "../../configuration";

interface ICoilGroupSelctorProps {
}

const mapStateToProps = (state: RootState) => {
    return {
        coilGroup: state.coilGroup,
        currCamera: state.camera.name,
        currCoilGroup: state.coilGroup.name,
        currCoilGroupId: state.coilGroup.id,
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentCoilGroup: (name: string) => dispatch(setCurrentCoilGroup(name)),
        setCoils: (values: string[]) => dispatch(setCoils(values)),
        setCurrentCoil: (name: string) => dispatch(setCurrentCoil(name)),
        setCurrSelectedImage: (name: string) => dispatch(setImage(name)),
        setMultipleCurrentCoil: (name: string[]) => dispatch(setMultipleCurrentCoil(name)),
        setCurrentCamera: (name: string) => dispatch(setCurrentCamera(name)),
        setCurrentCoilGroupId: (id: string) => dispatch(setCurrentCoilGroupId(id)),
        setCurrentCameraId: (id: string) => dispatch(setCurrentCameraId(id)),
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ICoilGroupSelectorReduxProps = PropsFromRedux & ICoilGroupSelctorProps;

type ICoilGroupSelectorState = {
    coilGroup: string[],
    currCoilGroup: string,
    currCamera: string,
    currCoilGroupId: string
}

class CoilGroupSelector extends Component<ICoilGroupSelectorReduxProps, ICoilGroupSelectorState>
{

    constructor(props) {
        super(props)
        this.state = {
            coilGroup: this.props.coilGroup.coilGroups,
            currCoilGroup: this.props.currCoilGroup,
            currCamera: this.props.currCamera,
            currCoilGroupId: this.props.currCoilGroupId
        }
    }

    getCoilgroupvalues() {
        
        let coilGroups;
        if(this.props.currCamera)
            coilGroups = getCoilgroupByCamera(this.props.currCamera); 
        else
            coilGroups = getCoilgroups(this.props.currentFurnaceId);

        coilGroups = coilGroups && coilGroups.filter((cg,i, cgs) => cgs.indexOf(cg) === i)
        //this.props.setCurrentCameraId(furnaceCameras[0].name)
        return coilGroups;
    }

    componentDidMount(): void {
        if(this.props.currCoilGroup === "")
        {
            let coilGroups = getCoilgroups(this.props.currentFurnaceId);
            this.props.setCurrentCoilGroupId(coilGroups[0].id)
            this.setState({
                    currCoilGroup: coilGroups[0].name
                });
            this.props.setCurrentCoilGroup(coilGroups[0].name);
        }
    }

    componentDidUpdate(prevProps, prevState) {
            this.props.setCoils([])
            this.props.setCurrentCoil('')
            if(this.props.currCoilGroup !== prevProps.currCoilGroup){
                this.props.setCurrentCoilGroup(this.props.currCoilGroup)
                this.setState({currCoilGroup:this.props.currCoilGroup})  
            }
            
    }

    handleSelection = (e) => {
        this.props.setCurrentCoilGroupId(e.nativeEvent.target.getAttribute("data-coilgroupid"))
        const newVal = e.target.value;
        this.setState({ currCoilGroup: newVal });
        let cameraData = getCameraByCoilgroup(newVal);
        this.props.setCurrentCamera(cameraData[0].display_name)
        this.props.setCurrentCameraId(cameraData[0].id)
        this.props.setCurrentCoilGroup(newVal);
        this.props.setMultipleCurrentCoil([])
    }

    render(): React.ReactNode {
        return (
            <div className="control-header element">
                <div className="container-typography">
                    <Typography><b>Coilgroup: </b></Typography>
                </div>
                <Select autoWidth value={this.state.currCoilGroup} onChange={(event) => this.handleSelection(event)} style={{ width: "100%" }}>
                    {this.getCoilgroupvalues().map((coilGroup, i) => (
                        <MenuItem key={`COILGROUP-SELECT-Coilgroup-${i}`} data-coilgroupid={coilGroup.id} value={coilGroup.name}>
                            {"Coilgroup "+coilGroup.name}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        )
    }
}

export default connector(CoilGroupSelector);