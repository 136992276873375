import { v4 as uuidv4 } from "uuid";
import {
  getDefaultDetailedPiona,
  getDefaultDiscreteComposition,
  getDefaultPlantReferenceId,
  getDefaultShortPiona,
  getDefaultHeavyFeed,
} from "../configuration";
import {
  IComponent,
  IDetailedPionaComponent,
  IDiscreteCompositionComponent,
  IMethod,
  IShortPionaComponent,
  UNIT,
  IHeavyComponent,
  IHeavyFeedStockMethod,
  HEAVYFEEDCOMPONENTUNIT,
  ISulphurContent,
} from "./IComponent";
import { isSulphurContent } from "../utilities/helperFunctions";

export enum FEEDSTOCK_STATUS {
  UNCOMPUTED = "UNCOMPUTED",
  RUNNING = "RUNNING",
  NORMALIZED = "NORMALIZED",
  COMPUTED = "COMPUTED",
  ERROR = "ERROR",
}

export enum FEEDSTOCK_TYPES {
  DISCRETE_COMPOSITION = "DISCRETE_COMPOSITION",
  PIONA = "PIONA",
  DETAILED_PIONA = "DETAILED_PIONA",
  SHORT_PIONA = "SHORT_PIONA",
  // HEAVY_FEEDSTOCK = "HEAVY_FEEDSTOCK",
  HEAVY_FEED = "HEAVY_FEED",
}

export type IFeedstock = {
  id: string;
  name: string;
  unit: UNIT;
  type: FEEDSTOCK_TYPES;
  feedstock:
    | IDiscreteComposition
    | IDetailedPiona
    | IShortPiona
    | IHeavyFeedstock;
  feed_type_id: string;
  status: FEEDSTOCK_STATUS;
  error_message: string;
  signature: string;
  is_public?: boolean;
  is_display_leave_popup?: boolean;
  is_saved?: boolean;
  is_changed?: boolean;
};
export type IFeedstockComposition =
  | IDiscreteComposition
  | IDetailedPiona
  | IShortPiona
  | IHeavyFeedstock;
export type IFeedstockComponent = IDiscreteCompositionComponent[] &
  IDetailedPionaComponent[] &
  IShortPionaComponent[] &
  IComponent[];

export type IDiscreteComposition = {
  components: IDiscreteCompositionComponent[];
  sulphur_content?: ISulphurContent;
};

export type IDetailedPiona = {
  components: IDetailedPionaComponent[];
  sulphur_content?: ISulphurContent;
};

export type IShortPiona = {
  components: IShortPionaComponent[];
  method: IMethod;
  sulphur_content?: ISulphurContent;
};

export type IHeavyFeedstock = {
  // components: IComponent[];
  hcunit: string;
  denunit: string;
  pinaunit: string;
  sulfurunit: string;
  components: IHeavyComponent[];
  method: IHeavyFeedStockMethod;
};

export function getDefaultFeedstock(
  feedstockType: FEEDSTOCK_TYPES = FEEDSTOCK_TYPES.DISCRETE_COMPOSITION
): IFeedstock {
  return {
    id: uuidv4(),
    name: "default",
    unit: UNIT.WT,
    type: feedstockType,
    feed_type_id: "2",
    feedstock: getDiscreteComposition(),
    status: FEEDSTOCK_STATUS.UNCOMPUTED,
    error_message: "",
    signature: "",
    is_public: true,
    is_display_leave_popup: false,
    is_saved: false,
  };
}

export function getFeedstockByType(
  type: FEEDSTOCK_TYPES
): IDiscreteComposition | IDetailedPiona | IShortPiona | IHeavyFeedstock {
  switch (type) {
    case FEEDSTOCK_TYPES.DISCRETE_COMPOSITION:
      return getDiscreteComposition();
    case FEEDSTOCK_TYPES.DETAILED_PIONA:
      return getDetailedPiona();
    case FEEDSTOCK_TYPES.SHORT_PIONA:
      return getShortPiona();
    // case FEEDSTOCK_TYPES.HEAVY_FEEDSTOCK:
    case FEEDSTOCK_TYPES.HEAVY_FEED:
      return getHeavyFeedstock();
    default:
      return getDiscreteComposition();
  }
}

export function getDiscreteComposition(): IDiscreteComposition {
  let discreteComposition: IDiscreteComposition = {
    components: getDefaultDiscreteComposition(getDefaultPlantReferenceId()),
  };

  if (isSulphurContent()) {
    discreteComposition.sulphur_content = { unit: UNIT.WT, value: 0, molecular_weight: 32 };
  }
  return discreteComposition;
}

export function getDetailedPiona(): IDetailedPiona {
  const piona: IDetailedPiona = {
    components: getDefaultDetailedPiona(getDefaultPlantReferenceId()),
  };
  if (isSulphurContent()) {
    piona.sulphur_content = { unit: UNIT.WT, value: 0, molecular_weight: 32 };
  }
  return piona;
}

export function getShortPiona(): IShortPiona {
  const piona: IShortPiona = {
    components: getDefaultShortPiona(FEEDSTOCK_TYPES.SHORT_PIONA),
    method: new IMethod(),
  };
  if (isSulphurContent()) {
    piona.sulphur_content = { unit: UNIT.WT, value: 0, molecular_weight: 32 };
  }
  return piona;
}

export function getHeavyFeedstock(): IHeavyFeedstock {
  const heavy: IHeavyFeedstock = {
    // components: getDefaultShortPiona(FEEDSTOCK_TYPES.HEAVY_FEEDSTOCK),
    hcunit: HEAVYFEEDCOMPONENTUNIT.HCRATIO,
    denunit: HEAVYFEEDCOMPONENTUNIT.DENSITY,
    pinaunit: HEAVYFEEDCOMPONENTUNIT.DESULPGASOIL,
    sulfurunit: HEAVYFEEDCOMPONENTUNIT.SULFURCONT,
    components: getDefaultHeavyFeed(FEEDSTOCK_TYPES.HEAVY_FEED),
    method: new IHeavyFeedStockMethod(),
  };
  return heavy;
}
