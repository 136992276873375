import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { IDetailedPionaComponent, IShortPionaComponent, ShortPionaMethod, UNIT } from '../../interfaces/IComponent';
import { IDetailedPiona, IDiscreteComposition, IFeedstock, IShortPiona } from '../../interfaces/IFeedstock';
import { RootState } from '../../reducers/rootReducer';
import { toggleMethod, toggleSulphurUnit, toggleUnit, updateComponent, updateSignature, updateSulphurComponent } from '../../slices/feedstockSlice';
import EditableCell from '../editableCell';
import equal from 'fast-deep-equal';
import { getTemperatureUnit } from '../../configuration';
import { isSulphurContent } from '../../utilities/helperFunctions';
import { is } from 'date-fns/locale';

interface IShortPionaFeedstockProps {
    feedstock: IFeedstock;
}

const mapStateToProps = (state: RootState) => {
    return {
        feedstocks: state.feedstock.feedstocks
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleUnit: (feedstock_id: string, unit_type: UNIT) => dispatch(toggleUnit({ feedstock_id, unit_type })),
        toggleSulphurUnit: (feedstock_id: string, unit_type: UNIT) => dispatch(toggleSulphurUnit({ feedstock_id, unit_type })),
        updateSulphurComponent: (feedstock_id: string, new_value: number) => dispatch(updateSulphurComponent({ feedstock_id, new_value })),
        updateComponent: (feedstock_id: string, component_id: string, new_value: number, field_name: string) => dispatch(updateComponent({ feedstock_id, component_id, new_value, field_name })),
        updateMethod: (feedstock_id: string, component_id: string, new_value: number, field_name: string, IsMethodChange: boolean = true, method: string) => dispatch(updateComponent({ feedstock_id, component_id, new_value, field_name, IsMethodChange, method })),
        toggleMethod: (feedstock_id: string, method_name: ShortPionaMethod) => {
            dispatch(toggleMethod({ feedstock_id, method_name }))
        },
        updateSignature: (feedstock_id: string) => dispatch(updateSignature({ feedstock_id })),
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps);


type PropsFromRedux = ConnectedProps<typeof connector>;

type IShortPionaFeedstockReduxProps = PropsFromRedux & IShortPionaFeedstockProps;
type IShortPionaFeedstockState = {
    showDialog: boolean;
    nextMethod: ShortPionaMethod;
    refresh: boolean;
}
class ShortPionaFeedstock extends Component<IShortPionaFeedstockReduxProps, IShortPionaFeedstockState>{

    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            nextMethod: this.getCurrentMethodName(),
            refresh: false
        }
    }

    handleComponentUpdate(mockE: { target: { id: string, name: string, value: number } }) {
        this.props.updateComponent(this.props.feedstock.id, mockE.target.id, mockE.target.value, mockE.target.name);
    }
    componentDidUpdate(prevProps) {
        if (!equal(this.props.feedstock.feedstock.components, prevProps.feedstock.feedstock.components) || !equal((this.props.feedstock.feedstock as IShortPiona).method, (prevProps.feedstock.feedstock as IShortPiona).method) && prevProps.feedstock.id === this.props.feedstock.id && this.props.feedstock.is_saved === false) {
            this.props.updateSignature(this.props.feedstock.id);
        }
        if (prevProps.feedstock.id === this.props.feedstock.id && this.props.feedstock.unit !== prevProps.feedstock.unit) {
            this.props.updateSignature(this.props.feedstock.id);
        }
    }
    handleMethodValueUpdate(mockE: { target: { id: string, name: string, value: number } }) {
        this.props.updateMethod(this.props.feedstock.id, mockE.target.id, mockE.target.value, mockE.target.name, true, this.getCurrentMethodName());
        this.setState({
            refresh: !this.state.refresh
        })
    }

    getCurrentMethodName(): ShortPionaMethod {
        return ShortPionaMethod[(this.props.feedstock.feedstock as IShortPiona).method.id];
    }

    toggleShowDialog() {
        this.setState({
            showDialog: !this.state.showDialog
        })
    }

    chnageMethod(feedstock_id: string, component_id: ShortPionaMethod) {

        this.props.toggleMethod(feedstock_id, component_id);
        if (this.state.showDialog)
            this.toggleShowDialog();
    }

    getCurrentFeedstockMethod() {
        return (this.props.feedstock.feedstock as IShortPiona).method.distribution;
    }

    toggleMethod(feedstock_id: string, component_id: ShortPionaMethod) {

        this.setState({
            nextMethod: component_id
        })
        const totalTemp = ((this.props.feedstock.feedstock as IShortPiona).method)?.distribution.map((mm, i) => Number(mm.value)).reduce((a, b) => a + b)
        if (totalTemp > 0)
            this.toggleShowDialog();
        else
            this.chnageMethod(feedstock_id, component_id);

    }
    render() {
        return (
            <div className="container" >
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={4}>

                        <div className="feedstock">
                            <label key="radion_wt" className="radio-buttons__item" title="radio_wt">
                                <input className={"radio-buttons__input_wt"} name="filter" type="radio" onChange={() => this.props.toggleUnit(this.props.feedstock.id, UNIT.WT)} value="wt" checked={this.props.feedstock.unit === UNIT.WT} /> PIONA Wt %
                            </label>
                            &emsp;
                            <label key="radion_vol" className="radio-buttons__item" title="radio_vol">
                                <input className={"radio-buttons__input_VOL"} name="filter" type="radio" value="vol" onChange={() => this.props.toggleUnit(this.props.feedstock.id, UNIT.VOL)} checked={this.props.feedstock.unit === UNIT.VOL} /> PIONA VOL %
                            </label>
                            <div className="feedstock-table-wrapper">
                                <table id="feedstock-table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Composition %</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(this.props.feedstock.feedstock.components as IShortPionaComponent[]).map((component, i) => {
                                            return (
                                                <tr key={component.id} id={"tr - ".concat(component.id)} onFocus={() => (true)}>
                                                    <td id={"name - ".concat(component.id)}>{component.name}</td>

                                                    <EditableCell
                                                        key={component.id + ''}
                                                        cellUpdate={(mockE: { target: { id: string, name: string, value: number } }) => this.handleComponentUpdate(mockE)}
                                                        cellData={{
                                                            type: "Mannual Modification",
                                                            value: component.value,
                                                            id: "" + component.id,
                                                        }}
                                                    />

                                                </tr>
                                            );
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={4}>

                        <div className="feedstock">
                            <label key="radion_simDest" className="radio-buttons__item2" title="radio_simDest">
                                <input className={"radio-buttons__input_simDest"} name="Methodfilter" type="radio" onChange={() => this.toggleMethod(this.props.feedstock.id, ShortPionaMethod.simDest)} value="simDest" checked={(this.props.feedstock.feedstock as IShortPiona).method.id === ShortPionaMethod.simDest} /> simDest D2887
                            </label>
                            &emsp;
                            <label key="radion_tbp" className="radio-buttons__item2" title="radio_tbp">
                                <input className={"radio-buttons__input_tdp"} name="Methodfilter" type="radio" onChange={() => this.toggleMethod(this.props.feedstock.id, ShortPionaMethod.TBP)} value="TBP" checked={(this.props.feedstock.feedstock as IShortPiona).method.id === ShortPionaMethod.TBP} /> TBP
                            </label>
                            &emsp;
                            <label key="radion_astm" className="radio-buttons__item2" title="radio_astm">
                                <input className={"radio-buttons__input_astm"} name="Methodfilter" type="radio" onChange={() => this.toggleMethod(this.props.feedstock.id, ShortPionaMethod.ASTM)} value="ASTM" checked={(this.props.feedstock.feedstock as IShortPiona).method.id === ShortPionaMethod.ASTM} /> ASTM D86
                            </label>
                            <div className="feedstock-table-wrapper">
                                <table id="feedstock-table">
                                    <thead>
                                        <tr>
                                            <th>Vol. %</th>
                                            <th>Boiling Point ({getTemperatureUnit()})</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.getCurrentFeedstockMethod().map((mm, i) => {

                                            return (<tr key={mm.id} id={"tr - " + mm.id} onFocus={() => (true)}>
                                                <td id={"name - " + mm.id + this.getCurrentMethodName()}>{mm.id}</td>

                                                <EditableCell
                                                    key={this.getCurrentMethodName() + '-' + mm.id + ''}
                                                    cellUpdate={(mockE: { target: { id: string, name: string, value: number } }) => this.handleMethodValueUpdate(mockE)}
                                                    cellData={{
                                                        type: this.getCurrentMethodName() + '-' + mm.id,
                                                        value: mm.value,
                                                        id: this.getCurrentMethodName() + '-' + mm.id,
                                                    }}
                                                />
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                {isSulphurContent() && this.props.feedstock.feedstock.hasOwnProperty('sulphur_content') &&
                    <div className="sulphur-content">
                        <span className="sulphur-content__title">Sulphur Content</span>
                        &emsp;
                        <label key="radion_wt" className="radio-buttons__item" title="radio_wt">
                            <input className="radio-buttons__input_wt" name="Sulphurfilter" type="radio" onChange={() => this.props.toggleSulphurUnit(this.props.feedstock.id, UNIT.WT)} value="wt" checked={(this.props.feedstock.feedstock as IDetailedPiona | IShortPiona | IDiscreteComposition).sulphur_content?.unit === UNIT.WT} /> Wt %
                        </label>
                        &emsp;
                        <label key="radion_mol" className="radio-buttons__item" title="radio_mol">
                            <input className="radio-buttons__input_MOL" name="Sulphurfilter" type="radio" value="mol" onChange={() => this.props.toggleSulphurUnit(this.props.feedstock.id, UNIT.MOL)} checked={(this.props.feedstock.feedstock as IDetailedPiona | IShortPiona | IDiscreteComposition).sulphur_content?.unit === UNIT.MOL} /> MOL %
                        </label>
                        <table id="feedstock-table" style={{ width: "32.5%" }}>
                            <tbody>
                                <tr>
                                    <td>Sulphur</td>
                                    <td>32</td>
                                    <EditableCell cellUpdate={(e) => this.props.updateSulphurComponent(this.props.feedstock.id, e.target.value)} cellData={{
                                        id: '',
                                        value: (this.props.feedstock.feedstock as IDetailedPiona | IShortPiona | IDiscreteComposition).sulphur_content?.value,
                                        type: '',
                                        disabled: undefined
                                    }} />
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
                <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                    <DialogTitle>Warning</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to discard your changes?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button className="button" onClick={() => this.toggleShowDialog()} >
                            Cancel
                        </button>
                        <button className="button" onClick={() => this.chnageMethod(this.props.feedstock.id, this.state.nextMethod)} >
                            Ok
                        </button>
                    </DialogActions>
                </Dialog>
            </div >
        )
    }

}

export default connector(ShortPionaFeedstock);


