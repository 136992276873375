import { Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fade, FormControlLabel, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { FEEDSTOCK_TYPES, IDetailedPiona, IDiscreteComposition, IFeedstock, IShortPiona } from "../../interfaces/IFeedstock";
import { RootState } from "../../reducers/rootReducer";
import { addFeedstock, updateSignature } from "../../slices/feedstockSlice";
import { v4 as uuidv4 } from 'uuid';
import { getFeedstockTypesAvailable } from "../../configuration";
import { checkFeedStockName } from "../../services/feedstockServices";
import { AuthContextProps, withAuth } from "react-oidc-context";
import { getToken, isSulphurContent } from "../../utilities/helperFunctions";
import { UNIT } from "../../interfaces/IComponent";

interface ICloneFeedstockProps {
    feedstock: IFeedstock;
}
interface OIDCAuth {
    auth?: AuthContextProps
 }
const mapStateToProps = (state: RootState) => {
    return {
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        currentPlantId: state.scenarioSimulator.currentPlantId,
        feedstocks: state.feedstock.feedstocks
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addFeedstock: (newFeedstock: IFeedstock) => dispatch(addFeedstock(newFeedstock)),
        updateSignature: (feedstock_id: string) => dispatch(updateSignature({ feedstock_id })),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ICloneFeedstockReduxProps = PropsFromRedux & ICloneFeedstockProps & OIDCAuth;

type ICloneFeedstockState = {
    showDialog: boolean;
    name: string;
    type: FEEDSTOCK_TYPES;
    isPublic: boolean;
    isNameAvailable: boolean;
    nameErrorMessage: string;
    showLoading: boolean;
}

class CloneFeedstock extends Component<ICloneFeedstockReduxProps, ICloneFeedstockState>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            name: this.props.feedstock.name,
            type: this.props.feedstock.type,
            isPublic: this.props.feedstock.is_public ? this.props.feedstock.is_public : false,
            isNameAvailable: false,
            nameErrorMessage: "",
            showLoading: false,
        }
    }

    toggleShowDialog() {

        let getName = this.props.feedstock.is_public === true ? this.props.feedstock.name.replace('(PUBLIC)', '') : this.props.feedstock.name;
        this.setState({
            showDialog: !this.state.showDialog,
            name: getName + "-COPY",
            isNameAvailable: false,
            nameErrorMessage: "",
            type: this.props.feedstock.type,
            isPublic: this.props.feedstock.is_public ? this.props.feedstock.is_public : false,
        })
    }

    handleNameInput(event) {
        this.setState({
            name: event.target.value.toUpperCase()
        })
    }

    async cloneNewFeedstock() {
        if (!!this.state.name?.trim()) {
            this.setState({
                showLoading: true,
            })
            // Default feed_type is 2, define feedtype based on feedstock type
            // let feed_type = '2';
            // if (this.state.type === FEEDSTOCK_TYPES.SHORT_PIONA || this.state.type === FEEDSTOCK_TYPES.DETAILED_PIONA) {
            //     feed_type = '4';
            // }

            let setName = this.state.isPublic === true ? this.state.name.trim() + "(PUBLIC)" : this.state.name.trim();
            let feedstockCopy = { ...this.props.feedstock.feedstock };
            if(isSulphurContent() && !this.props.feedstock.feedstock.hasOwnProperty('sulphur_content')) {
                (feedstockCopy as IShortPiona | IDetailedPiona | IDiscreteComposition).sulphur_content = {unit: UNIT.WT, value:0, molecular_weight:32}
            }
            const newFeedstock: IFeedstock = {
                id: uuidv4(),
                name: setName,
                unit: this.props.feedstock.unit,
                type: this.props.feedstock.type,
                feedstock: feedstockCopy,
                feed_type_id: this.props.feedstock.feed_type_id,
                status: this.props.feedstock.status,
                error_message: this.props.feedstock.error_message,
                signature: this.props.feedstock.signature,
                is_public: this.state.isPublic,
                is_display_leave_popup: true,
                is_saved: false
            }


            try {
                const accessToken = getToken(this.props.auth);
                let isNameInRedux = this.props.feedstocks.some(feedstock => feedstock.name === newFeedstock.name);
                if (isNameInRedux) {
                    this.setState({
                        isNameAvailable: true,
                        nameErrorMessage: "Name already exist!",
                        showLoading: false,
                    })
                } else {
                    let isNameAvailable = await checkFeedStockName(newFeedstock, accessToken);

                    if (isNameAvailable.status) {
                        this.setState({
                            isNameAvailable: true,
                            nameErrorMessage: !!isNameAvailable.message ? isNameAvailable.message : "Name already exist!",
                            showLoading: false,
                        })
                    } else {
                        this.props.addFeedstock(newFeedstock);
                        this.props.updateSignature(this.props.feedstock.id);
                        this.toggleShowDialog();
                        this.setState({
                            showLoading: false,
                        })
                    }
                }
            } catch (error) {
                // Api error hendaling 
                this.setState({
                    isNameAvailable: true,
                    showLoading: false,
                    nameErrorMessage: "Oops somthing went to wrong!"
                })
            }

        } else {
            this.setState({
                isNameAvailable: true,
                nameErrorMessage: "Please enter a name!",
                showLoading: false,
            })
        }
    }

    handleTypeChange(event) {
        this.setState({
            type: event.target.value
        })
    }

    handleChangeIsPublic(event) {
        this.setState({
            isPublic: !this.state.isPublic
        })
    }

    render() {
        return (
            <div className="control-header element">
                <button
                    className="button"
                    onClick={() => this.toggleShowDialog()}
                    disabled={!this.props.feedstock.is_saved}
                >
                    Clone
                </button>
                <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Clone feedstock</DialogTitle>
                    <DialogContent className="hight-220">

                        <Typography component={'span'} variant={'body2'}>
                            To clone a new feedstock, please provide the following information:
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid container item xs={12} spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Name*"
                                        type="text"
                                        fullWidth
                                        defaultValue={this.state.name}
                                        value={this.state.name}
                                        onChange={(event) => this.handleNameInput(event)}
                                        helperText={this.state.nameErrorMessage}
                                        error={this.state.isNameAvailable}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3}>
                                <Grid item xs={6}>
                                    <TextField
                                        id={"type"}
                                        select
                                        label={"Type"}
                                        defaultValue={this.state.type}
                                        onChange={(event) => this.handleTypeChange(event)}
                                        disabled={true}
                                    >
                                        {getFeedstockTypesAvailable().map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.isPublic}
                                                onChange={this.handleChangeIsPublic.bind(this)}
                                                name="isPublic"
                                                value={this.state.isPublic}
                                                color="primary"
                                            />
                                        }
                                        label="Is Public?"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <button className="button" onClick={() => this.toggleShowDialog()} >
                            Cancel
                        </button>
                        <button className="button" onClick={() => this.cloneNewFeedstock()} disabled={this.state.showLoading} >
                            Add {this.state.showLoading === true && (
                                <Fade
                                    in={this.state.showLoading}
                                    style={{
                                        transitionDelay: this.state.showLoading ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                >
                                    <CircularProgress size={11} />
                                </Fade>
                            )}
                        </button>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

export default withAuth(connector(CloneFeedstock));