import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getDefaultShortPionaMethodDistributions,
  getDefaultHeavyFeedStockMethodDistributions,
} from "../configuration";
import {
  IDetailedPionaComponent,
  IDiscreteCompositionComponent,
  IMethodDistribution,
  ShortPionaMethod,
  UNIT,
  HeavyFeedMethod,
  HEAVYFEEDCOMPONENTUNIT,
} from "../interfaces/IComponent";
import {
  FEEDSTOCK_STATUS,
  FEEDSTOCK_TYPES,
  IDetailedPiona,
  IDiscreteComposition,
  IFeedstock,
  IShortPiona,
  IHeavyFeedstock,
} from "../interfaces/IFeedstock";
import { IProductPredictorFeedstockDetail } from "../interfaces/IProductPredictor";
import { getSignature } from "../utilities/helperFunctions";

export type IFeedstockSlice = {
  feedstocks: IFeedstock[];
  loadedFeedstocks: IFeedstock[];
  current_feedstock_id: string;
  new_selected_feedstock_id: string;
  convection: string;
  showDesignData: string;
  loading: boolean;
  molPerc: string;
};

const initialState: IFeedstockSlice = {
  feedstocks: [],
  loadedFeedstocks: [],
  current_feedstock_id: "",
  new_selected_feedstock_id: "",
  convection: "false",
  showDesignData: "visible",
  loading: false,
  molPerc: "Wt %",
};

export const feedstockSlice = createSlice({
  name: "feedstockSlice",
  initialState: initialState,
  reducers: {
    addFeedstock: (state, action: PayloadAction<IFeedstock>) => {
      state.feedstocks.push(action.payload);
      state.current_feedstock_id = action.payload.id;
    },
    deleteFeedstock: (state, action) => {
      state.feedstocks = state.feedstocks.filter(
        (item) => item.id !== action.payload
      );
      if (state.feedstocks.length > 0) {
        state.current_feedstock_id =
          state.feedstocks[state.feedstocks.length - 1].id;
      }
      if (state.feedstocks.length === 0) {
        state.current_feedstock_id = "-1";
      }
    },
    setCurrentFeedstockId: (state, action: PayloadAction<string>) => {
      state.current_feedstock_id = action.payload;
    },
    toggleUnit: (
      state,
      action: PayloadAction<{ feedstock_id: string; unit_type: UNIT }>
    ) => {
      const feedstock_index = state.feedstocks.findIndex(
        (feedstock) => feedstock.id === action.payload.feedstock_id
      );
      state.feedstocks[feedstock_index].unit = action.payload.unit_type;
      state.feedstocks[feedstock_index].is_display_leave_popup = true;
      state.feedstocks[feedstock_index].is_saved = false;
    },
    toggleSulphurUnit: (
      state,
      action: PayloadAction<{ feedstock_id: string; unit_type: UNIT }>
    ) => {
      const feedstock_index = state.feedstocks.findIndex(
        (feedstock) => feedstock.id === action.payload.feedstock_id
      );
      const sulphurContent = (state.feedstocks[feedstock_index].feedstock as IShortPiona | IDetailedPiona | IDiscreteComposition).sulphur_content;
      if (sulphurContent) {
        sulphurContent.unit = action.payload.unit_type;
      }
      state.feedstocks[feedstock_index].is_display_leave_popup = true;
      state.feedstocks[feedstock_index].is_saved = false;
    },
    toggleUnitHeavyFeed: (
      state,
      action: PayloadAction<{
        feedstock_id: string;
        unit_type: HEAVYFEEDCOMPONENTUNIT;
      }>
    ) => {
      const feedstock_index = state.feedstocks.findIndex(
        (feedstock) => feedstock.id === action.payload.feedstock_id
      );

      if (
        action.payload.unit_type === HEAVYFEEDCOMPONENTUNIT.HCRATIO ||
        action.payload.unit_type === HEAVYFEEDCOMPONENTUNIT.REFRECTIVEINDEX ||
        action.payload.unit_type === HEAVYFEEDCOMPONENTUNIT.UNKNOWN
      )
        (
          state.feedstocks[feedstock_index].feedstock as IHeavyFeedstock
        ).hcunit = action.payload.unit_type;

      if (
        action.payload.unit_type === HEAVYFEEDCOMPONENTUNIT.DENSITY ||
        action.payload.unit_type === HEAVYFEEDCOMPONENTUNIT.SPGRAVITY
      )
        (
          state.feedstocks[feedstock_index].feedstock as IHeavyFeedstock
        ).denunit = action.payload.unit_type;

      if (
        action.payload.unit_type === HEAVYFEEDCOMPONENTUNIT.DESULPGASOIL ||
        action.payload.unit_type === HEAVYFEEDCOMPONENTUNIT.HYDROGASOIL ||
        action.payload.unit_type === HEAVYFEEDCOMPONENTUNIT.STRAIGHTRUN
      )
        (
          state.feedstocks[feedstock_index].feedstock as IHeavyFeedstock
        ).pinaunit = action.payload.unit_type;

      if (action.payload.unit_type === HEAVYFEEDCOMPONENTUNIT.SULFURCONT)
        (
          state.feedstocks[feedstock_index].feedstock as IHeavyFeedstock
        ).sulfurunit = action.payload.unit_type;
    },
    updateComponent: (
      state,
      action: PayloadAction<{
        feedstock_id: string;
        component_id: string;
        new_value: number;
        field_name?: string;
        IsMethodChange?: boolean;
        method?: string;
      }>
    ) => {
      const feedstock_index = state.feedstocks.findIndex(
        (feedstock) => feedstock.id === action.payload.feedstock_id
      );
      const component_index = state.feedstocks[
        feedstock_index
      ].feedstock.components.findIndex(
        (component) => component.id === action.payload.component_id
      );
      var feedstock:
      | IDiscreteComposition
      | IDetailedPiona
      | IShortPiona
      | IHeavyFeedstock;
      switch (state.feedstocks[feedstock_index].type) {
        case FEEDSTOCK_TYPES.DISCRETE_COMPOSITION: {
          feedstock = state.feedstocks[feedstock_index]
            .feedstock as IDiscreteComposition;
          feedstock.components[component_index].value =
            action.payload.new_value;
          state.feedstocks[feedstock_index].status =
            FEEDSTOCK_STATUS.UNCOMPUTED;
          state.feedstocks[feedstock_index].is_display_leave_popup = true;
          state.feedstocks[feedstock_index].is_saved = false;
          break;
        }
        case FEEDSTOCK_TYPES.DETAILED_PIONA: {
          feedstock = state.feedstocks[feedstock_index]
            .feedstock as IDetailedPiona;
          feedstock.components[component_index][
            action.payload.field_name ?? ""
          ] = action.payload.new_value;
          state.feedstocks[feedstock_index].status =
            FEEDSTOCK_STATUS.UNCOMPUTED;
          state.feedstocks[feedstock_index].is_display_leave_popup = true;
          state.feedstocks[feedstock_index].is_saved = false;
          break;
        }
        case FEEDSTOCK_TYPES.SHORT_PIONA: {
          if (action.payload.IsMethodChange === true) {
            feedstock = state.feedstocks[feedstock_index]
              .feedstock as IShortPiona;
            const fieldname = action.payload.field_name?.split("-")[1];
            feedstock.method.distribution.filter(
              (dd) => dd.id === fieldname
            )[0].value = action.payload.new_value; // = 0; // = action.payload.new_value;
          } else {
            feedstock = state.feedstocks[feedstock_index]
              .feedstock as IShortPiona;
            feedstock.components[component_index].value =
              action.payload.new_value;
          }
          state.feedstocks[feedstock_index].status =
            FEEDSTOCK_STATUS.UNCOMPUTED;
          state.feedstocks[feedstock_index].is_display_leave_popup = true;
          state.feedstocks[feedstock_index].is_saved = false;
          break;
        }
        case FEEDSTOCK_TYPES.HEAVY_FEED: {
          if (action.payload.IsMethodChange === true) {
            feedstock = state.feedstocks[feedstock_index]
              .feedstock as IHeavyFeedstock;
            const fieldname = action.payload.field_name?.split("-")[1];
            feedstock.method.distribution.filter(
              (dd) => dd.id === fieldname
            )[0].value = action.payload.new_value; // = 0; // = action.payload.new_value;
          } else {
            feedstock = state.feedstocks[feedstock_index]
              .feedstock as IHeavyFeedstock;
            feedstock.components[component_index].value =
              action.payload.new_value;
          }
          state.feedstocks[feedstock_index].status =
            FEEDSTOCK_STATUS.UNCOMPUTED;
          state.feedstocks[feedstock_index].is_display_leave_popup = true;
          state.feedstocks[feedstock_index].is_saved = false;
          break;
        }
      }
    },
    updateSulphurComponent: (
      state,
      action) => {
      const feedstock_index = state.feedstocks.findIndex(
        (feedstock) => feedstock.id === action.payload.feedstock_id
      );
      const sulphurContent = (state.feedstocks[feedstock_index].feedstock as IDetailedPiona | IShortPiona | IDiscreteComposition).sulphur_content;
      if (sulphurContent) {
        sulphurContent.value = action.payload.new_value;
      }
      state.feedstocks[feedstock_index].is_display_leave_popup = true;
      state.feedstocks[feedstock_index].is_saved = false;
    },
    toggleMethod: (
      state,
      action: PayloadAction<{
        feedstock_id: string;
        method_name: ShortPionaMethod;
      }>
    ) => {
      const feedstock_index = state.feedstocks.findIndex(
        (feedstock) => feedstock.id === action.payload.feedstock_id
      );
      const methodDistribution: IMethodDistribution[] =
        getDefaultShortPionaMethodDistributions(action.payload.method_name);
      const method_name = action.payload.method_name;
      (
        state.feedstocks[feedstock_index].feedstock as IShortPiona
      ).method.distribution = methodDistribution;
      (state.feedstocks[feedstock_index].feedstock as IShortPiona).method.id =
        method_name;
      (state.feedstocks[feedstock_index].feedstock as IShortPiona).method.name =
        method_name;
    },
    toggleHeavyFeedMethod: (
      state,
      action: PayloadAction<{
        feedstock_id: string;
        method_name: HeavyFeedMethod;
      }>
    ) => {
      const feedstock_index = state.feedstocks.findIndex(
        (feedstock) => feedstock.id === action.payload.feedstock_id
      );
      const methodDistribution: IMethodDistribution[] =
        getDefaultHeavyFeedStockMethodDistributions(action.payload.method_name);
      const method_name = action.payload.method_name;
      (
        state.feedstocks[feedstock_index].feedstock as IHeavyFeedstock
      ).method.distribution = methodDistribution;
      (
        state.feedstocks[feedstock_index].feedstock as IHeavyFeedstock
      ).method.id = method_name;
      (
        state.feedstocks[feedstock_index].feedstock as IHeavyFeedstock
      ).method.name = method_name;
    },
    normalizeFeedstock: (state, action: PayloadAction<string>) => {
      const feedstock_index = state.feedstocks.findIndex(
        (feedstock) => feedstock.id === action.payload
      );
      const feedstock_type: FEEDSTOCK_TYPES =
        state.feedstocks[feedstock_index].type;

      if (
        state.feedstocks[feedstock_index].status !== FEEDSTOCK_STATUS.NORMALIZED
      ) {
        switch (feedstock_type) {
          case FEEDSTOCK_TYPES.DISCRETE_COMPOSITION:
            // Check if the feedstock is empty
            const feedstock_components = state.feedstocks[feedstock_index]
              .feedstock.components as IDiscreteCompositionComponent[];
            
        // Filter out the Sulphur component for normalization calculations
        const filtered_components = feedstock_components.filter(
          (component) => component.name !== 'Sulphur'
        );

        const value_sum = filtered_components.reduce(
          (accumulator, component) => accumulator + Number(component.value),
          0
        );

        if (value_sum === 0) {
          state.feedstocks[feedstock_index].status = FEEDSTOCK_STATUS.ERROR;
          state.feedstocks[feedstock_index].error_message =
            "Can't update LVF because feedstock values of scenario are empty.";
          break;
        }

        // Round all components except Sulphur
        filtered_components.forEach((component) => {
          component.value =
            Math.round(
              (parseFloat(component.value.toString()) / value_sum) * 10000
            ) / 100;
        });

        const feedstock_components_rounded = filtered_components;
        // If sum differs from hundred, apply deviation to first non-zero component
        const computed_value_sum = feedstock_components_rounded.reduce(
          (accumulator, component) => accumulator + Number(component.value),
          0
        );

        if (computed_value_sum !== 100) {
          const deviation = 100 - computed_value_sum;
          const firstNonZeroIndex = feedstock_components_rounded.findIndex(
            (component) => parseFloat(component.value.toString()) > 0
          );
          feedstock_components_rounded[firstNonZeroIndex].value =
            Math.round(
              (feedstock_components_rounded[firstNonZeroIndex].value += deviation) * 100
            ) / 100;
        }

        // Merge the original Sulphur component back into the normalized components
        const sulphurComponent = feedstock_components.find(
          (component) => component.name === 'Sulphur'
        );
        if (sulphurComponent) {
          feedstock_components_rounded.push(sulphurComponent);
        }

        // Update the state with the normalized components including Sulphur
        state.feedstocks[feedstock_index].feedstock.components = feedstock_components_rounded;

        // Set the status of the feedstock to NORMALIZED
        state.feedstocks[feedstock_index].status = FEEDSTOCK_STATUS.NORMALIZED;
        break;
          case FEEDSTOCK_TYPES.DETAILED_PIONA:
            // Check if the feedstock is empty
            const components = state.feedstocks[feedstock_index].feedstock
              .components as IDetailedPionaComponent[];
            const totals: number[] = [];
            const N_Paraffins = components.reduce(
              (old, c) => old + Number(c.N_Paraffins),
              0
            );
            const I_Paraffins = components.reduce(
              (old, x) => old + Number(x.I_Paraffins),
              0
            );
            const Olefins = components.reduce(
              (old, x) => old + Number(x.Olefins),
              0
            );
            const Naphthenes = components.reduce(
              (old, x) => old + Number(x.Naphthenes),
              0
            );
            const Aromatics = components.reduce(
              (old, x) => old + Number(x.Aromatics),
              0
            );
            totals.push(
              N_Paraffins,
              I_Paraffins,
              Olefins,
              Naphthenes,
              Aromatics
            );
            const total = totals.reduce(
              (accumulator, component) => accumulator + Number(component),
              0
            );
            if (total === 0) {
              state.feedstocks[feedstock_index].status = FEEDSTOCK_STATUS.ERROR;
              state.feedstocks[feedstock_index].error_message =
                "Can't update LVF because feedstock values of scenario are empty.";
              break;
            }
            if (total !== 100) {
              const N_Paraffins_total_normalize = (N_Paraffins * 100) / total;
              const I_Paraffins_total_normalize = (I_Paraffins * 100) / total;
              const Olefins_total_normalize = (Olefins * 100) / total;
              const Naphthenes_total_normalize = (Naphthenes * 100) / total;
              const Aromatics_total_normalize = (Aromatics * 100) / total;
              components.forEach((c) => {
                c.N_Paraffins =
                  Number(N_Paraffins) > 0
                    ? Number(
                        (
                          (c.N_Paraffins * N_Paraffins_total_normalize) /
                          N_Paraffins
                        ).toFixed(4)
                      )
                    : 0;
                c.I_Paraffins =
                  Number(I_Paraffins) > 0
                    ? Number(
                        (
                          (c.I_Paraffins * I_Paraffins_total_normalize) /
                          I_Paraffins
                        ).toFixed(4)
                      )
                    : 0;
                c.Olefins =
                  Number(Olefins) > 0
                    ? Number(
                        (
                          (c.Olefins * Olefins_total_normalize) /
                          Olefins
                        ).toFixed(4)
                      )
                    : 0;
                c.Naphthenes =
                  Number(Naphthenes) > 0
                    ? Number(
                        (
                          (c.Naphthenes * Naphthenes_total_normalize) /
                          Naphthenes
                        ).toFixed(4)
                      )
                    : 0;
                c.Aromatics =
                  Number(Aromatics) > 0
                    ? Number(
                        (
                          (c.Aromatics * Aromatics_total_normalize) /
                          Aromatics
                        ).toFixed(4)
                      )
                    : 0;
              });
            }
            // Set the status of the feedstock to NORMALIZED
            state.feedstocks[feedstock_index].status =
              FEEDSTOCK_STATUS.NORMALIZED;
            break;
          case FEEDSTOCK_TYPES.SHORT_PIONA:
            // Check if the feedstock is empty
            const shortfeedComponent = state.feedstocks[feedstock_index]
              .feedstock as IShortPiona;

            const shortpiona_value_sum = shortfeedComponent.components
              .filter((c) => c.id !== "d1515")
              .reduce(
                (accumulator, component) =>
                  accumulator + Number(component.value),
                0
              );
            if (shortpiona_value_sum === 0) {
              state.feedstocks[feedstock_index].status = FEEDSTOCK_STATUS.ERROR;
              state.feedstocks[feedstock_index].error_message =
                "Can't update LVF because feedstock values of scenario are empty.";
              break;
            }
            state.feedstocks[feedstock_index].feedstock.components
              .filter((c) => c.id !== "d1515")
              .forEach((component) => {
                component.value = Number(
                  (
                    (parseFloat(component.value.toString()) * 100) /
                    shortpiona_value_sum
                  ).toFixed(2)
                );
              });
            const shortpiona_rounded_value_sum = shortfeedComponent.components
              .filter((c) => c.id !== "d1515")
              .reduce(
                (accumulator, component) =>
                  accumulator + Number(component.value),
                0
              );

            // If sum differs from hundred, apply deviation to first non-zero component
            if (shortpiona_rounded_value_sum !== 100) {
              const deviation = 100 - shortpiona_value_sum;
              const firstNonZeroIndex = shortfeedComponent.components.findIndex(
                (component) => component.value > 0
              );
              const newVal =
                Math.round(
                  (Number(
                    shortfeedComponent.components[firstNonZeroIndex].value
                  ) +
                    Number(deviation)) *
                    100
                ) / 100;
              shortfeedComponent.components[firstNonZeroIndex].value = newVal;
            }

            // Set the status of the feedstock to NORMALIZED
            state.feedstocks[feedstock_index].status =
              FEEDSTOCK_STATUS.NORMALIZED;
            break;
            case FEEDSTOCK_TYPES.HEAVY_FEED:
              const heavyfeedComponent = state.feedstocks[feedstock_index]
                .feedstock as IHeavyFeedstock;
  
                heavyfeedComponent.components.filter((c) => {
                  return (
                    c.id !== "valueh" &&
                    c.id !== "temperatureh" &&
                    c.id !== "valued" &&
                    c.id !== "temperatured" &&
                    c.id !== "sulfurcontent"
                  );
                }).forEach(x=> {
                  x.value = x.value ? x.value : 0
                })

                //Only PINA componenet has beed normalized
              // const heavypiona_value_sum = heavyfeedComponent.components
              //   .filter((c) => {
              //     return (
              //       c.id !== "valueh" &&
              //       c.id !== "temperatureh" &&
              //       c.id !== "valued" &&
              //       c.id !== "temperatured" &&
              //       c.id !== "sulfurcontent"
              //     );
              //   })
              //   .reduce(
              //     (accumulator, component) =>
              //       accumulator + Number(component.value),
              //     0
              //   );
  
              // if (heavypiona_value_sum === 0) {
              //   state.feedstocks[feedstock_index].status = FEEDSTOCK_STATUS.ERROR;
              //   state.feedstocks[feedstock_index].error_message =
              //     "Can't update LVF because feedstock values of scenario are empty.";
              //   break;
              // }
  
              // state.feedstocks[feedstock_index].feedstock.components
              //   .filter((c) => {
              //     return (
              //       c.id !== "valueh" &&
              //       c.id !== "temperatureh" &&
              //       c.id !== "valued" &&
              //       c.id !== "temperatured" &&
              //       c.id !== "sulfurcontent"
              //     );
              //   })
              //   .forEach((component) => {
              //     component.value = Number(
              //       (
              //         (parseFloat(component.value.toString()) * 100) /
              //         heavypiona_value_sum
              //       ).toFixed(2)
              //     );
              //   });
  
              // const heavypiona_rounded_value_sum = heavyfeedComponent.components
              //   .filter((c) => {
              //     return (
              //       c.id !== "valueh" &&
              //       c.id !== "temperatureh" &&
              //       c.id !== "valued" &&
              //       c.id !== "temperatured" &&
              //       c.id !== "sulfurcontent"
              //     );
              //   })
              //   .reduce(
              //     (accumulator, component) =>
              //       accumulator + Number(component.value),
              //     0
              //   );
  
              // If sum differs from hundred, apply deviation to first non-zero component
              // if (heavypiona_rounded_value_sum !== 100) {
              //   const deviation = 100 - heavypiona_value_sum;
              //   const firstNonZeroIndex = heavyfeedComponent.components.findIndex(
              //     (component) => component.value > 0
              //   );
              //   const newVal =
              //     Math.round(
              //       (Number(
              //         heavyfeedComponent.components[firstNonZeroIndex].value
              //       ) +
              //         Number(deviation)) *
              //         100
              //     ) / 100;
              //   heavyfeedComponent.components[firstNonZeroIndex].value = newVal;
              // }
              // Write code here if other component need normalization. in discussion.
  
              // let heavyfeedmethodValues =
              //   heavyfeedComponent.method.distribution.map((d) =>
              //     Number(d.value)
              //   );
  
              // Set the status of the feedstock to NORMALIZED
              state.feedstocks[feedstock_index].status =
                FEEDSTOCK_STATUS.NORMALIZED;
  
              break;
  
          default:
            break;
        }
      }
    },
    InterpolateHeavyFeedstock: (state, action: PayloadAction<string>) => {
      const feedstock_index = state.feedstocks.findIndex(
        (feedstock) => feedstock.id === action.payload
      );
      const feedstock_type: FEEDSTOCK_TYPES =
        state.feedstocks[feedstock_index].type;

      //const MethodBoilingPoint = state.feedstocks[feedstock_index].feedstock.findIndex(c => c.value > 0 );
      const heavyfeedComponent = state.feedstocks[feedstock_index]
        .feedstock as IHeavyFeedstock;
      const MethodBoilingPoint = heavyfeedComponent.method.distribution.filter(
        (d) => d.value > 0
      ).length;

      if (MethodBoilingPoint <= 3) {
        state.feedstocks[feedstock_index].status = FEEDSTOCK_STATUS.ERROR;
        state.feedstocks[feedstock_index].error_message =
          "Please provide value for atleast 3 Boiling Points";
        return;
      }
      // if (state.feedstocks[feedstock_index].status !== FEEDSTOCK_STATUS.NORMALIZED) {
      switch (feedstock_type) {
        case FEEDSTOCK_TYPES.HEAVY_FEED:
          const heavyfeedComponent = state.feedstocks[feedstock_index]
            .feedstock as IHeavyFeedstock;
          const st = heavyfeedComponent.method
            .distribution as IMethodDistribution[];

          interpolate(st);
          break;

        default:
          break;
      }
      // }
    },
    updateLoadedFeedstocks: (state, action: PayloadAction<IFeedstock[]>) => {
      state.loadedFeedstocks = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    set_mol_perc: (state, action: PayloadAction<string>) => {
      state.molPerc = action.payload;
    },
    resetFeedstocks: (state) => {
      state.feedstocks = [] as IFeedstock[];
      state.loadedFeedstocks = [];
      state.current_feedstock_id = "";
    },
    updateFeedtype: (
      state,
      action: PayloadAction<{ feedstock_id: string; feedtype_id: string }>
    ) => {
      let current_feedstock = state.feedstocks.filter(
        (f) => f.id === action.payload.feedstock_id
      )[0];
      current_feedstock.feed_type_id = action.payload.feedtype_id;
    },
    updateSignature: (
      state,
      action: PayloadAction<{ feedstock_id: string }>
    ) => {
      let current_feedstock = state.feedstocks.filter(
        (f) => f.id === action.payload.feedstock_id
      )[0];

      const { id, status, error_message, signature, ...feedstock } =
        current_feedstock;
      current_feedstock.signature = getSignature(feedstock);
    },
    normalizeProductPredictorFeedstock: (
      state,
      action: PayloadAction<{ feedstocks: IProductPredictorFeedstockDetail[] }>
    ) => {
      action.payload.feedstocks.forEach((x) => {
        const feedstock_index = state.feedstocks.findIndex(
          (feedstock) => feedstock.id === x.feedstock_id
        );
        if (feedstock_index > -1) {
          const feedstock_type: FEEDSTOCK_TYPES =
            state.feedstocks[feedstock_index].type;

          if (
            state.feedstocks[feedstock_index].status !==
            FEEDSTOCK_STATUS.NORMALIZED
          ) {
            switch (feedstock_type) {
              case FEEDSTOCK_TYPES.DISCRETE_COMPOSITION:
                // Check if the feedstock is empty
                const feedstock_components = state.feedstocks[feedstock_index]
                  .feedstock.components as IDiscreteCompositionComponent[];
                const value_sum = feedstock_components.reduce(
                  (accumulator, component) =>
                    accumulator + Number(component.value),
                  0
                );
                if (value_sum === 0) {
                  state.feedstocks[feedstock_index].status =
                    FEEDSTOCK_STATUS.ERROR;
                  state.feedstocks[feedstock_index].error_message =
                    "Can't update LVF because feedstock values of scenario are empty.";
                  break;
                }

                // Round all components
                state.feedstocks[feedstock_index].feedstock.components.forEach(
                  (component) => {
                    component.value =
                      Math.round(
                        (parseFloat(component.value.toString()) / value_sum) *
                          10000
                      ) / 100;
                  }
                );

                const feedstock_components_rounded = state.feedstocks[
                  feedstock_index
                ].feedstock.components as IDiscreteCompositionComponent[];
                // If sum differs from hundred, apply deviation to first non-zero component
                const computed_value_sum = feedstock_components_rounded.reduce(
                  (accumulator, component) =>
                    accumulator + Number(component.value),
                  0
                );
                if (computed_value_sum !== 100) {
                  const deviation = 100 - computed_value_sum;
                  const firstNonZeroIndex = state.feedstocks[
                    feedstock_index
                  ].feedstock.components.findIndex(
                    (component) => parseFloat(component.value.toString()) > 0
                  );
                  state.feedstocks[feedstock_index].feedstock.components[
                    firstNonZeroIndex
                  ].value =
                    Math.round(
                      (state.feedstocks[feedstock_index].feedstock.components[
                        firstNonZeroIndex
                      ].value += deviation) * 100
                    ) / 100;
                }

                // Set the status of the feedstock to NORMALIZED
                state.feedstocks[feedstock_index].status =
                  FEEDSTOCK_STATUS.NORMALIZED;
                break;
              case FEEDSTOCK_TYPES.DETAILED_PIONA:
                // Check if the feedstock is empty
                const components = state.feedstocks[feedstock_index].feedstock
                  .components as IDetailedPionaComponent[];
                const totals: number[] = [];
                const N_Paraffins = components.reduce(
                  (old, c) => old + Number(c.N_Paraffins),
                  0
                );
                const I_Paraffins = components.reduce(
                  (old, x) => old + Number(x.I_Paraffins),
                  0
                );
                const Olefins = components.reduce(
                  (old, x) => old + Number(x.Olefins),
                  0
                );
                const Naphthenes = components.reduce(
                  (old, x) => old + Number(x.Naphthenes),
                  0
                );
                const Aromatics = components.reduce(
                  (old, x) => old + Number(x.Aromatics),
                  0
                );
                totals.push(
                  N_Paraffins,
                  I_Paraffins,
                  Olefins,
                  Naphthenes,
                  Aromatics
                );
                const total = totals.reduce(
                  (accumulator, component) => accumulator + Number(component),
                  0
                );
                if (total === 0) {
                  state.feedstocks[feedstock_index].status =
                    FEEDSTOCK_STATUS.ERROR;
                  state.feedstocks[feedstock_index].error_message =
                    "Can't update LVF because feedstock values of scenario are empty.";
                  break;
                }
                if (total !== 100) {
                  const N_Paraffins_total_normalize =
                    (N_Paraffins * 100) / total;
                  const I_Paraffins_total_normalize =
                    (I_Paraffins * 100) / total;
                  const Olefins_total_normalize = (Olefins * 100) / total;
                  const Naphthenes_total_normalize = (Naphthenes * 100) / total;
                  const Aromatics_total_normalize = (Aromatics * 100) / total;
                  components.forEach((c) => {
                    c.N_Paraffins = Number(
                      (
                        (c.N_Paraffins * N_Paraffins_total_normalize) /
                        N_Paraffins
                      ).toFixed(4)
                    );
                    c.I_Paraffins = Number(
                      (
                        (c.I_Paraffins * I_Paraffins_total_normalize) /
                        I_Paraffins
                      ).toFixed(4)
                    );
                    c.Olefins = Number(
                      ((c.Olefins * Olefins_total_normalize) / Olefins).toFixed(
                        4
                      )
                    );
                    c.Naphthenes = Number(
                      (
                        (c.Naphthenes * Naphthenes_total_normalize) /
                        Naphthenes
                      ).toFixed(4)
                    );
                    c.Aromatics = Number(
                      (
                        (c.Aromatics * Aromatics_total_normalize) /
                        Aromatics
                      ).toFixed(4)
                    );
                  });
                }
                // Set the status of the feedstock to NORMALIZED
                state.feedstocks[feedstock_index].status =
                  FEEDSTOCK_STATUS.NORMALIZED;
                break;
              case FEEDSTOCK_TYPES.SHORT_PIONA:
                // Check if the feedstock is empty
                const shortfeedComponent = state.feedstocks[feedstock_index]
                  .feedstock as IShortPiona;

                const shortpiona_value_sum = shortfeedComponent.components
                  .filter((c) => c.id !== "d1515")
                  .reduce(
                    (accumulator, component) =>
                      accumulator + Number(component.value),
                    0
                  );
                if (shortpiona_value_sum === 0) {
                  state.feedstocks[feedstock_index].status =
                    FEEDSTOCK_STATUS.ERROR;
                  state.feedstocks[feedstock_index].error_message =
                    "Can't update LVF because feedstock values of scenario are empty.";
                  break;
                }
                state.feedstocks[feedstock_index].feedstock.components
                  .filter((c) => c.id !== "d1515")
                  .forEach((component) => {
                    component.value = Number(
                      (
                        (parseFloat(component.value.toString()) * 100) /
                        shortpiona_value_sum
                      ).toFixed(2)
                    );
                  });
                const shortpiona_rounded_value_sum =
                  shortfeedComponent.components
                    .filter((c) => c.id !== "d1515")
                    .reduce(
                      (accumulator, component) =>
                        accumulator + Number(component.value),
                      0
                    );

                // If sum differs from hundred, apply deviation to first non-zero component
                if (shortpiona_rounded_value_sum !== 100) {
                  const deviation = 100 - shortpiona_value_sum;
                  const firstNonZeroIndex =
                    shortfeedComponent.components.findIndex(
                      (component) => component.value > 0
                    );
                  const newVal =
                    Math.round(
                      (Number(
                        shortfeedComponent.components[firstNonZeroIndex].value
                      ) +
                        Number(deviation)) *
                        100
                    ) / 100;
                  shortfeedComponent.components[firstNonZeroIndex].value =
                    newVal;
                }

                // Set the status of the feedstock to NORMALIZED
                state.feedstocks[feedstock_index].status =
                  FEEDSTOCK_STATUS.NORMALIZED;
                break;
              default:
                break;
            }
          }
        }
      });
    },
    modifyFeedstocks: (state, action: PayloadAction<IFeedstock>) => {
      const feedstock_index = state.feedstocks.findIndex(
        (feedstock) => feedstock.id === action.payload.id
      );
      state.feedstocks[feedstock_index] = action.payload;
    },
    loadAllFeedStockData: (state, action: PayloadAction<IFeedstock[]>) => {
      if (state.feedstocks.length === 0) {
        state.current_feedstock_id = "-1";
      }
      state.feedstocks = [...state.feedstocks, ...action.payload];
    },
    setNewSelectedFeedstockId: (state, action: PayloadAction<string>) => {
      state.new_selected_feedstock_id = action.payload;
    },
  },
});

function interpolate(st: IMethodDistribution[]) {
  let allelement: boolean[] = [];
  let firstindex = -1;
  let secondindex = -1;

  st.forEach((item) => {
    item.value > 0 ? allelement.push(true) : allelement.push(false);
  });
  if (allelement.indexOf(true) !== -1) {
    while (true) {
      for (let i = 0; i < st.length; i++){
        st[i].value > 0 ? allelement.push(true) : allelement.push(false);
      };

      if (firstindex === -1) firstindex = allelement.indexOf(true);
      secondindex = allelement.indexOf(true, firstindex + 1);

      if (firstindex === -1 || secondindex === -1) {
        console.log("Between No element");
        break;
      }

      let count = secondindex - firstindex;
      if (count > 1) {
        let value1 = st[firstindex].value;
        let value2 = st[secondindex].value;

        const finalarray = lerp(value1, value2, count);

        let j = 0;
        for (var i = firstindex + 1; i < secondindex; i++) {
          st[i].value = finalarray[j++];
          allelement = [];
          firstindex = -1;
          secondindex = -1;
        }
      } else {
        firstindex = secondindex;
        allelement = [];
        secondindex = -1;
      }
    }

    firstindex = -1;
    secondindex = -1;
    allelement = [];
    while (true) {
      for (let i = 0; i < st.length; i++){
        st[i].value > 0 ? allelement.push(true) : allelement.push(false);
      };

      if (firstindex === -1) firstindex = allelement.indexOf(true);
      secondindex = allelement.indexOf(true, firstindex + 1);

      if (firstindex === -1 || secondindex === -1 || firstindex === 0) {
        console.log("Start No element");
        break;
      }
      let count = secondindex - firstindex;

      if (count === 1) {
        let value1 = st[firstindex].value;
        let value2 = st[secondindex].value;
        const finalarray = ferp(value1, value2, count);

        let j = 0;
        ///for(var i = firstindex+1;i<secondindex;i++)
        // {
          st[firstindex - 1].value = finalarray[j];
          allelement = [];
          firstindex = -1;
          secondindex = -1;
        // }
      } else {
        firstindex = secondindex;
        allelement = [];
        secondindex = -1;
      }
    }

    firstindex = -1;
    secondindex = -1;
    allelement = [];
    while (true) {
      for (let i = 0; i < st.length; i++){
        st[i].value > 0 ? allelement.push(true) : allelement.push(false);
      };

      if (secondindex === -1) secondindex = allelement.indexOf(false) - 1;
      firstindex = secondindex - 1;

      if (firstindex === -3 || secondindex === -2) {
        console.log("End No element");
        break;
      }

      let count = secondindex - firstindex;

      if (count === 1) {
        let value1 = st[firstindex].value;
        let value2 = st[secondindex].value;
        const finalarray = eerp(value1, value2, count);

        let j = 0;
        ///for(var i = firstindex+1;i<secondindex;i++)
        // {
          st[secondindex + 1].value = finalarray[j];
          allelement = [];
          firstindex = -1;
          secondindex = -1;
        // }
      } else {
        firstindex = secondindex;
        allelement = [];
        secondindex = -1;
      }
    }
  }
}

function delta(value1, value2, count) {
  return (value2 - value1) / count;
}

function lerp(value1, value2, count) {
  let arr: number[] = [];
  //   let arr = [];
  const amount = delta(value1, value2, count);
  for (var i = 1; i <= count - 1; i++) {
    arr.push(value1 + i * amount);
  }
  return arr;
}

function ferp(value1, value2, count) {
  let arr: number[] = [];
  //   let arr = [];
  const amount = delta(value1, value2, count);
  for (var i = 1; i <= count; i++) {
    arr.push(value1 - i * amount);
  }
  return arr;
}

function eerp(value1, value2, count) {
  let arr: number[] = [];
  //   let arr = [];
  const amount = delta(value1, value2, count);
  for (var i = 1; i <= count; i++) {
    arr.push(value2 + i * amount);
  }
  return arr;
}


export const {
  addFeedstock,
  deleteFeedstock,
  setCurrentFeedstockId,
  toggleUnit,
  toggleSulphurUnit,
  toggleUnitHeavyFeed,
  toggleMethod,
  toggleHeavyFeedMethod,
  updateComponent,
  updateSulphurComponent,
  normalizeFeedstock,
  InterpolateHeavyFeedstock,
  updateLoadedFeedstocks,
  setLoading,
  set_mol_perc,
  resetFeedstocks,
  updateFeedtype,
  updateSignature,
  normalizeProductPredictorFeedstock,
  modifyFeedstocks,
  loadAllFeedStockData,
  setNewSelectedFeedstockId,
} = feedstockSlice.actions;

export default feedstockSlice.reducer;
